"use client";

import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import FormButton from "../ui/auth/FormButton";
import LogoDark from "@/assets/svg/logo-dark.svg";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";

const PageNotFound = () => {
  const router = useRouter();

  const backHandler = () => {
    router.back();
  };

  useEffect(() => {
    captureException(`Error 404, page ${window.location.href} not found`);
  }, []);

  return (
    <Grid h={"100vh"} maxW={"100vw"} placeItems={"center"}>
      <Flex flexDir={"column"} rowGap={"24px"}>
        <Flex alignItems={"center"} justifyContent={"center"} w={"100%"}>
          <Link href={"/"} alt={"Home"}>
            <LogoDark />
          </Link>
        </Flex>
        <Heading
          fontSize={"96px"}
          fontWeight={"700"}
          letterSpacing={"0.68px"}
          lineHeight={"normal"}
          fontFamily={"Roslindale"}
          color={"mainBlue"}
          textAlign={"center"}
        >
          404
        </Heading>
        <Text
          textAlign={"center"}
          color={"grey"}
          fontSize={"24px"}
          fontWeight={"500"}
          lineHeight={"1.35"}
        >
          Page not found
        </Text>
        <FormButton title={"Back"} button={{ onClick: backHandler }} />
      </Flex>
    </Grid>
  );
};

export default PageNotFound;
